.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.background-video {
  width: 100%;
  top: -50px;
  position: absolute;
  opacity: 1;
  -webkit-mask-image: linear-gradient(to top, transparent 15%, #181bb6 100%);
  mask-image: linear-gradient(to top, transparent 15%, #181bb6 100%);
}

.background-video > video {
  width: 100%;
  position: relative;
}


.big-languaje-icon{
  width: 50px;
  height: 50px;
}

.languaje-icon{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 5px solid #fff;
  background-color: #ebebeb;
  overflow: hidden;
}

.languaje-proyect-icon{
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.me-large-img{
  width: auto;
  max-height: 533px;
}

.locale-img{
  width: 270px;
  height: 180px;
}

.img-social-am{
  width: auto;
  height: 25px;
}

.progress-container {
  width: 100%;
  text-align: center;
}

.img-flag{
  width: auto;
  height: 35px;
}

.wp-icon{
  height: 35px;
  width: auto;
}

.wp-text{
  font-size: 16px;
  font-weight: bold;
  line-height: 34px;
  margin-left: 5px;
}

.commant::before{
  content: '@';
}

.progress {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-percent{
  font-size: 12px;
}

.progress-bar {	
  height: 18px;
  border-radius: 4px; 
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-striped .progress-bar { 	
  animation: progressAnimationStrike 6s;
}

@keyframes progressAnimationStrike {
     from { width: 0 }
     to   { width: 100% }
}
 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


:focus-visible {
  outline: transparent;
}
